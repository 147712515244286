import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import "./App.css";
import {
	VolumeHighIcon,
	Timer02Icon,
	TimeHalfPassIcon,
	ArrowExpandIcon,
	Time04Icon,
	PlayIcon,
	PauseIcon,
	StopIcon,
	TimeQuarterPassIcon,
	Cancel01Icon,
	Add01Icon,
	MinusSignIcon,
	AlarmClockIcon,
	Location01Icon,
	Location05Icon,
	SunriseIcon,
	SunsetIcon,
	AiBeautifyIcon,
	EarthIcon,
	Calendar03Icon,
	Menu01Icon,
	MapsCircle01Icon,
	ArrowDown01Icon,
	ArrowUp01Icon,
} from "hugeicons-react";
import { toWords } from "number-to-words";
import TimeZoneConverter from "./components/TimeZoneConverter";
import YearlyCalendar from "./components/YearlyCalendar";
import WorldTimeMap from "./components/WorldTimeMap";

const App = () => {
	const cities = [
		{
			city: "New York",
			timeZone: "America/New_York",
			lat: 40.7128,
			lon: -74.006,
		},
		{ city: "London", timeZone: "Europe/London", lat: 51.5074, lon: -0.1278 },
		{ city: "Paris", timeZone: "Europe/Paris", lat: 48.8566, lon: 2.3522 },
		{ city: "Delhi", timeZone: "Asia/Kolkata", lat: 28.7041, lon: 77.1025 },
		{
			city: "Sydney",
			timeZone: "Australia/Sydney",
			lat: -33.8688,
			lon: 151.2093,
		},
		{ city: "Beijing", timeZone: "Asia/Shanghai", lat: 39.9042, lon: 116.4074 },
		{ city: "Tokyo", timeZone: "Asia/Tokyo", lat: 35.6762, lon: 139.6503 },
		{ city: "Moscow", timeZone: "Europe/Moscow", lat: 55.7558, lon: 37.6173 },
	];

	const [time, setTime] = useState(new Date());
	const [locationTimeZone, setLocationTimeZone] = useState(
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);

	const [isDayTime, setIsDayTime] = useState(true);

	const [isFullScreen, setIsFullScreen] = useState(false);

	// Single state to track the active view: 'time', 'stopwatch', 'timezone', 'timer', 'yearlycalendar'
	const [activeView, setActiveView] = useState("time");

	const [stopwatchTime, setStopwatchTime] = useState(0); // Time for stopwatch in milliseconds
	const [isStopwatchRunning, setIsStopwatchRunning] = useState(false); // Stopwatch running state
	const [laps, setLaps] = useState([]); // State to store lap times
	const [showClearButton, setShowClearButton] = useState(false); // State for Clear button visibility

	const [isTimerRunning, setIsTimerRunning] = useState(false); // Timer running state
	const [timerTime, setTimerTime] = useState(10); // Default timer is now 10 seconds
	const [customMinutes, setCustomMinutes] = useState(0);
	const [customSeconds, setCustomSeconds] = useState(0);

	// State variables for the alert feature
	const [alertIntervalMinutes, setAlertIntervalMinutes] = useState(0);
	const [isAlertActive, setIsAlertActive] = useState(false);
	const [showAlertInput, setShowAlertInput] = useState(false);
	const alertIntervalRef = useRef(null);
	const alertMeRef = useRef(null);

	// Inside the App component function
	const formattedDate = moment(time).format("ddd, MMM D YYYY");

	// New state variables for location and time zone
	const [currentLocation, setCurrentLocation] = useState({
		city: "Sunnyvale",
		state: "California",
		country: "USA",
		latitude: 37.3688,
		longitude: -122.0363,
	});

	const [currentTimeZone, setCurrentTimeZone] = useState("America/Los_Angeles");
	const [gmtOffset, setGmtOffset] = useState("");
	const [sunriseTime, setSunriseTime] = useState("");
	const [sunsetTime, setSunsetTime] = useState("");
	const [isFetchingLocation, setIsFetchingLocation] = useState(false);

	const [weatherData, setWeatherData] = useState({});

	const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));

	const [timeMode, setTimeMode] = useState("normal"); // default to normal time
	const [showMode, setShowMode] = useState("");

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isClockDropdownOpen, setIsClockDropdownOpen] = useState(false);
	const [isTimezoneDropdownOpen, setIsTimezoneDropdownOpen] = useState(false);

	// Update the Unix time every second
	useEffect(() => {
		const unixTimer = setInterval(() => {
			setUnixTime(Math.floor(Date.now() / 1000));
		}, 1000);

		return () => clearInterval(unixTimer);
	}, []);

	// Fetch sunrise/sunset times and GMT offset
	useEffect(() => {
		// Fetch sunrise and sunset times
		fetch(
			`https://api.sunrise-sunset.org/json?lat=${currentLocation.latitude}&lng=${currentLocation.longitude}&formatted=0`
		)
			.then((response) => response.json())
			.then((data) => {
				const sunriseLocal = moment
					.utc(data.results.sunrise)
					.tz(currentTimeZone)
					.format("HH:mm");
				const sunsetLocal = moment
					.utc(data.results.sunset)
					.tz(currentTimeZone)
					.format("HH:mm");
				setSunriseTime(sunriseLocal);
				setSunsetTime(sunsetLocal);

				// Check if the current time is between sunrise and sunset
				const currentHour = moment(time).format("HH:mm");
				if (currentHour >= sunriseLocal && currentHour <= sunsetLocal) {
					setIsDayTime(true); // Daytime
				} else {
					setIsDayTime(false); // Nighttime
				}
			})
			.catch((error) =>
				console.error("Error fetching sunrise/sunset times:", error)
			);

		// Get GMT offset
		const offsetMinutes = moment.tz(currentTimeZone).utcOffset();
		const offsetHours = offsetMinutes / 60;
		const sign = offsetHours >= 0 ? "+" : "-";
		const offsetString = `GMT${sign}${Math.abs(offsetHours)}`;
		setGmtOffset(offsetString);
	}, [currentLocation, currentTimeZone, time]);

	// Apply the day/night theme to the body element
	useEffect(() => {
		if (isDayTime) {
			document.body.classList.add("day-theme");
			document.body.classList.remove("night-theme");
		} else {
			document.body.classList.add("night-theme");
			document.body.classList.remove("day-theme");
		}
	}, [isDayTime]);

	// Function to handle "Use Current Location" button click
	const handleUseCurrentLocation = () => {
		if (navigator.geolocation) {
			setIsFetchingLocation(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;

					// Reverse geocoding to get location details
					fetch(
						`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
					)
						.then((response) => response.json())
						.then((data) => {
							const city =
								data.address.city ||
								data.address.town ||
								data.address.village ||
								"";
							const state = data.address.state || "";
							const country = data.address.country || "";

							// Fetch time zone from coordinates
							const TIMEZONEDB_API_KEY = "YOUR_TIMEZONEDB_API_KEY"; // Replace with your API key
							fetch(
								`https://api.timezonedb.com/v2.1/get-time-zone?key=${TIMEZONEDB_API_KEY}&format=json&by=position&lat=${latitude}&lng=${longitude}`
							)
								.then((response) => response.json())
								.then((timeZoneData) => {
									const timeZone = timeZoneData.zoneName;
									setCurrentLocation({
										city,
										state,
										country,
										latitude,
										longitude,
									});
									setCurrentTimeZone(timeZone);
									setIsFetchingLocation(false);
								})
								.catch((error) => {
									console.error("Error fetching time zone:", error);
									setIsFetchingLocation(false);
								});
						})
						.catch((error) => {
							console.error("Error reverse geocoding:", error);
							setIsFetchingLocation(false);
						});
				},
				(error) => {
					console.error("Error getting current position:", error);
					setIsFetchingLocation(false);
				}
			);
		} else {
			alert("Geolocation is not supported by this browser.");
		}
	};

	// Update the current time every second
	useEffect(() => {
		const timer = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => clearInterval(timer); // Clean up the timer
	}, []);

	// Stopwatch timer logic
	useEffect(() => {
		let stopwatchInterval;
		if (isStopwatchRunning) {
			stopwatchInterval = setInterval(() => {
				setStopwatchTime((prevTime) => prevTime + 10); // Update every 10ms for milliseconds
			}, 10);
		} else if (!isStopwatchRunning && stopwatchTime !== 0) {
			clearInterval(stopwatchInterval);
		}
		return () => clearInterval(stopwatchInterval);
	}, [isStopwatchRunning]);

	// Timer countdown logic
	useEffect(() => {
		let timerInterval;
		if (isTimerRunning && timerTime > 0) {
			timerInterval = setInterval(() => {
				setTimerTime((prevTime) => prevTime - 1); // Decrease by 1 second
			}, 1000);
		} else if (timerTime <= 0) {
			clearInterval(timerInterval); // Stop timer when time runs out
			setIsTimerRunning(false); // Stop timer from running
		}
		return () => clearInterval(timerInterval);
	}, [isTimerRunning, timerTime]);

	// Modified formatTimeWithSeconds to accept currentTimeZone
	const formatTimeWithSeconds = (date, timeZone) => {
		const options = {
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
			timeZone: timeZone,
		};

		const formattedTimeParts = new Intl.DateTimeFormat(
			"en-US",
			options
		).formatToParts(date);

		const hours = formattedTimeParts.find((part) => part.type === "hour").value;
		const minutes = formattedTimeParts.find(
			(part) => part.type === "minute"
		).value;
		const seconds = formattedTimeParts.find(
			(part) => part.type === "second"
		).value;
		const dayPeriod = formattedTimeParts.find(
			(part) => part.type === "dayPeriod"
		).value; // AM or PM

		return (
			// <>
			// 	<span className="sw-time">{String(hours).padStart(2, "0")}</span>
			// 	<span className="sw-time-separator">:</span>
			// 	<span className="sw-time">{String(minutes).padStart(2, "0")}</span>
			// 	<span className="sw-time-separator">:</span>
			// 	<span className="sw-time">{String(seconds).padStart(2, "0")}</span>
			// 	<span className="sw-time-separator"> </span>{" "}
			// 	<span className="sw-time-am-pm no-mobile">{dayPeriod}</span>{" "}
			// 	<div className="time-period no-desk">
			// 		<span className="sw-time-am-pm">{dayPeriod}</span>
			// 	</div>
			// </>
			<div className="time-display-wrapper">
				<div className="time-main">
					<span className="sw-time">{String(hours).padStart(2, "0")}</span>
					<span className="sw-time-separator">:</span>
					<span className="sw-time">{String(minutes).padStart(2, "0")}</span>
					<span className="sw-time-separator">:</span>
					<span className="sw-time">{String(seconds).padStart(2, "0")}</span>
					<span className="sw-time-am-pm no-mobile">{dayPeriod}</span>{" "}
				</div>
				<div className="time-period no-desk">
					<span className="sw-time-am-pm">{dayPeriod}</span>
				</div>
			</div>
		);
	};

	// Timer formatting function (mm:ss)
	const formatTimer = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${String(minutes).padStart(2, "0")}:${String(
			remainingSeconds
		).padStart(2, "0")}`;
	};

	// Stopwatch formatting function (hh:mm:ss:ms)
	const formatStopwatchTime = (timeInMilliseconds) => {
		const hours = Math.floor(timeInMilliseconds / (60 * 60 * 1000));
		const minutes = Math.floor(
			(timeInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
		);
		const seconds = Math.floor((timeInMilliseconds % (60 * 1000)) / 1000);
		const milliseconds = timeInMilliseconds % 1000;

		return (
			<>
				<div>
					<span className="sw-time">{String(hours).padStart(2, "0")}</span>
					<span className="sw-time-separator">:</span>
					<span className="sw-time">{String(minutes).padStart(2, "0")}</span>
					<span className="sw-time-separator">:</span>
					<span className="sw-time">{String(seconds).padStart(2, "0")}</span>
					<span className="sw-time-separator">:</span>
					<span className="sw-time no-mobile">
						{String(milliseconds).padStart(3, "0")}
					</span>
				</div>
				<div className="sw-time-seconds">
					<span className="sw-time no-desk">
						{String(milliseconds).padStart(3, "0")}
					</span>
				</div>
			</>
		);
	};

	// Lap time formatting function (hh:mm:ss:ms)
	const formatLapTime = (timeInMilliseconds) => {
		const hours = Math.floor(timeInMilliseconds / (60 * 60 * 1000));
		const minutes = Math.floor(
			(timeInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
		);
		const seconds = Math.floor((timeInMilliseconds % (60 * 1000)) / 1000);
		const milliseconds = timeInMilliseconds % 1000;

		return (
			<>
				<span className="lap-time">{String(hours).padStart(2, "0")}</span>
				<span className="lap-time-separator">:</span>
				<span className="lap-time">{String(minutes).padStart(2, "0")}</span>
				<span className="lap-time-separator">:</span>
				<span className="lap-time">{String(seconds).padStart(2, "0")}</span>
				<span className="lap-time-separator">:</span>
				<span className="lap-time">
					{String(milliseconds).padStart(3, "0")}
				</span>
			</>
		);
	};

	// Increase timer by 10 seconds
	const increaseTimer = () => {
		setTimerTime((prev) => prev + 10);
	};

	// Decrease timer by 10 seconds, minimum is 10 seconds
	const decreaseTimer = () => {
		setTimerTime((prev) => (prev > 10 ? prev - 10 : 10));
	};

	// Start the stopwatch
	const handleStartStopwatch = () => {
		setIsStopwatchRunning(true);
	};

	// Pause the stopwatch
	const handlePauseStopwatch = () => {
		setIsStopwatchRunning(false);
	};

	// Stop the stopwatch and add the last lap
	const handleStopStopwatch = () => {
		if (isStopwatchRunning) {
			setLaps([...laps, stopwatchTime]); // Add last lap before stopping
		}
		setIsStopwatchRunning(false);
		setShowClearButton(true); // Show the "Clear" button when stopped
	};

	// Add lap for stopwatch
	const handleLap = () => {
		setLaps([...laps, stopwatchTime]); // Capture current stopwatch time as a lap
	};

	// Clear all laps
	const handleClearLaps = () => {
		setLaps([]); // Reset the laps array to empty
		setShowClearButton(false); // Hide "Clear" button after laps are cleared
	};

	// Start the timer
	const startTimer = () => {
		setIsTimerRunning(true);
	};

	// Stop the timer
	const stopTimer = () => {
		setIsTimerRunning(false);
	};

	// Function to detect if the user is on a mobile device
	const isMobileDevice = () => {
		return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
			navigator.userAgent
		);
	};

	// Toggle Fullscreen Mode
	const handleFullScreenToggle = () => {
		// Prevent fullscreen on mobile devices
		if (isMobileDevice()) {
			return;
		}

		setIsFullScreen(!isFullScreen);
	};

	// Timer
	useEffect(() => {
		let timeout;

		if (isTimerRunning && timerTime > 0) {
			timeout = setTimeout(() => {
				setTimerTime(timerTime - 1);
				console.log("Timer running, current time:", timerTime - 1);
			}, 1000);
		} else if (timerTime === 0 && isTimerRunning) {
			setIsTimerRunning(false);
			const speech = new SpeechSynthesisUtterance("Time's up");
			window.speechSynthesis.speak(speech);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [isTimerRunning, timerTime]);

	const handleSetTimer = () => {
		const minutes = parseInt(customMinutes) || 0;
		const seconds = parseInt(customSeconds) || 0;
		const totalSeconds = minutes * 60 + seconds;
		setTimerTime(totalSeconds); // Set the timer in total seconds
		setIsTimerRunning(true); // Start the timer
	};

	const handleSetAlert = () => {
		const minutes = parseInt(alertIntervalMinutes, 10);
		if (isNaN(minutes) || minutes <= 0) {
			return;
		}

		setIsAlertActive(true);

		// Clear any existing interval
		if (alertIntervalRef.current) {
			clearInterval(alertIntervalRef.current);
		}

		// Set up the interval to alert every X minutes
		alertIntervalRef.current = setInterval(() => {
			const speech = new SpeechSynthesisUtterance("Time's up");
			window.speechSynthesis.speak(speech);
		}, minutes * 60 * 1000);
	};

	const handleCancelAlert = () => {
		setIsAlertActive(false);
		setAlertIntervalMinutes(0);

		// Clear the alert interval
		if (alertIntervalRef.current) {
			clearInterval(alertIntervalRef.current);
			alertIntervalRef.current = null;
		}
	};

	useEffect(() => {
		return () => {
			if (alertIntervalRef.current) {
				clearInterval(alertIntervalRef.current);
			}
		};
	}, []);

	useEffect(() => {
		document.title = time.toLocaleTimeString("en-US", {
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
		});
	}, [time]);

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date()); // Updates main time and timezone times
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	// Function to toggle between time formats
	const toggleTimeMode = () => {
		setTimeMode((prevMode) => {
			let nextMode = "normal";
			if (prevMode === "normal") nextMode = "natural";
			if (prevMode === "natural") nextMode = "literal";

			// Show the current mode for a few seconds
			setShowMode(nextMode);
			setTimeout(() => {
				setShowMode(""); // Hide the mode after 3 seconds
			}, 3000); // Show for 3 seconds

			return nextMode;
		});
	};

	const formatNaturalTime = (date) => {
		const hours = date.getHours();
		const minutes = date.getMinutes();

		// Convert hours and next hour to words
		const hourString =
			hours === 0 || hours === 12 ? "twelve" : toWords(hours % 12);
		const nextHourString =
			(hours % 12) + 1 === 13 ? "one" : toWords((hours % 12) + 1);

		if (minutes === 0) {
			return `${hourString} o'clock`;
		} else if (minutes <= 30) {
			return `${toWords(minutes)} past ${hourString}`;
		} else {
			return `${toWords(60 - minutes)} to ${nextHourString}`;
		}
	};

	const formatLiteralTime = (date) => {
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const hourWords = toWords(hours); // convert number to words
		const minuteWords = toWords(minutes); // convert number to words

		return `${hourWords} ${minuteWords}`;
	};

	const handleClearStopwatch = () => {
		setStopwatchTime(0);
		setLaps([]);
	};

	// Function to scroll to the "Alert Me" section
	const scrollToAlertMe = () => {
		if (alertMeRef.current) {
			alertMeRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const clockDropdownRef = useRef(null);
	const timezoneDropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				clockDropdownRef.current &&
				!clockDropdownRef.current.contains(event.target)
			) {
				setIsClockDropdownOpen(false);
			}

			if (
				timezoneDropdownRef.current &&
				!timezoneDropdownRef.current.contains(event.target)
			) {
				setIsTimezoneDropdownOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isClockDropdownOpen, isTimezoneDropdownOpen]);

	return (
		<div className={`app ${isFullScreen ? "fullscreen-mode" : ""}`}>
			<div className="header">
				<div className="header-left" onClick={() => setActiveView("time")}>
					<img src="logo.png" className="logo" alt="Time On Toast Logo" />
					<span className="tot-title">TIME ON TOAST</span>
					<span className="time-type no-mobile" onClick={toggleTimeMode}>
						<AiBeautifyIcon size={15} />
					</span>
					{showMode && (
						<span className={`active-mode fade-out header-icons`}>
							Time mode: {showMode}
						</span>
					)}
				</div>
				<div className="header-right">
					{!isFullScreen && (
						<>
							<span
								className="tooltip"
								data-tooltip="Say it loud"
								onClick={() => {
									const speech = new SpeechSynthesisUtterance(
										`The current time is ${time.toLocaleTimeString()}`
									);
									speechSynthesis.speak(speech);
								}}>
								<VolumeHighIcon />
							</span>

							{/* Clock Dropdown */}
							<div
								className="dropdown-wrapper no-mobile"
								ref={clockDropdownRef}>
								<span
									className="header-icons dropdown-trigger"
									onClick={() => {
										setIsClockDropdownOpen(!isClockDropdownOpen);
										setIsTimezoneDropdownOpen(false); // Close Timezone dropdown
									}}>
									The Clock{" "}
									{isClockDropdownOpen ? (
										<ArrowUp01Icon />
									) : (
										<ArrowDown01Icon />
									)}
								</span>

								{isClockDropdownOpen && (
									<div className="dropdown-menu">
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("time");
												setIsClockDropdownOpen(false);
											}}>
											Know the Time
										</div>
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("timer");
												setIsClockDropdownOpen(false);
											}}>
											Set Timer
										</div>
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("stopwatch");
												setIsClockDropdownOpen(false);
											}}>
											Stopwatch
										</div>
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("time");
												setShowAlertInput(!showAlertInput);
												setIsClockDropdownOpen(false);
											}}>
											Alert Me
										</div>
										<div
											className="dropdown-item"
											onClick={() => {
												handleUseCurrentLocation();
												setIsClockDropdownOpen(false);
											}}>
											Current location
										</div>
									</div>
								)}
							</div>

							{/* Timezone Dropdown */}
							<div
								className="dropdown-wrapper header-icons"
								ref={timezoneDropdownRef}>
								<span
									className="header-icons dropdown-trigger"
									onClick={() => {
										setIsTimezoneDropdownOpen(!isTimezoneDropdownOpen);
										setIsClockDropdownOpen(false); // Close Clock dropdown
									}}>
									Timezone{" "}
									{isTimezoneDropdownOpen ? (
										<ArrowUp01Icon />
									) : (
										<ArrowDown01Icon />
									)}
								</span>

								{isTimezoneDropdownOpen && (
									<div className="dropdown-menu">
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("timezone");
												setIsTimezoneDropdownOpen(false);
											}}>
											Timezone converter
										</div>
										<div
											className="dropdown-item"
											onClick={() => {
												setActiveView("worldtime");
												setIsTimezoneDropdownOpen(false);
											}}>
											World time
										</div>
									</div>
								)}
							</div>

							<span
								className="hamburger-menu"
								onClick={() => setIsSidebarOpen(true)}>
								<Menu01Icon />
							</span>
						</>
					)}

					<span className="today header-icons">{formattedDate}</span>
				</div>
			</div>

			<div className="body">
				{/* Main Display for Time, Stopwatch, or Timer */}
				{activeView === "time" && (
					<div
						className={`time-display ${
							showAlertInput ? "alert-section-container" : ""
						}`}>
						<div className="time" onClick={handleFullScreenToggle}>
							<h1>
								{timeMode === "normal"
									? formatTimeWithSeconds(time, locationTimeZone)
									: timeMode === "natural"
									? formatNaturalTime(time)
									: formatLiteralTime(time)}
							</h1>
						</div>
					</div>
				)}
				{activeView === "stopwatch" && (
					<div className="time-display stopwatch">
						<div className="time">
							<h1>{formatStopwatchTime(stopwatchTime)}</h1>
							<div className="stopwatch-controls">
								{!isStopwatchRunning ? (
									<>
										<span
											onClick={handleStartStopwatch}
											className="button-default button-action">
											<PlayIcon /> Start
										</span>
										{stopwatchTime > 0 && (
											<span
												onClick={handleClearStopwatch}
												className="button-default">
												<Cancel01Icon /> Clear
											</span>
										)}
									</>
								) : (
									<>
										<span
											onClick={handlePauseStopwatch}
											className="button-default">
											<PauseIcon /> Pause
										</span>
										<span onClick={handleLap} className="button-default">
											<TimeQuarterPassIcon /> Lap
										</span>
										<span
											onClick={handleStopStopwatch}
											className="button-default">
											<StopIcon /> Stop
										</span>
									</>
								)}
							</div>
						</div>
						{laps.length > 0 && (
							<div className="laps">
								<div className="laps-header">
									<TimeQuarterPassIcon /> Laps
								</div>
								<div className="laps-time-container">
									{laps.map((lapTime, index) => (
										<div key={index} className="laps-time">
											Lap #{index + 1}: {formatLapTime(lapTime)}
										</div>
									))}
									{showClearButton && (
										<div>
											<span className="icon-text" onClick={handleClearLaps}>
												<Cancel01Icon size={15} /> Clear
											</span>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				)}
				{activeView === "timer" && (
					<div className="time-display timer">
						<div className="time">
							<h1>{formatTimer(timerTime)}</h1>
						</div>
						<div className="custom-timer-input">
							<input
								type="number"
								min="0"
								placeholder="Minutes"
								value={customMinutes}
								onChange={(e) => setCustomMinutes(e.target.value)}
							/>
							<span className="time-separator">:</span>
							<input
								type="number"
								min="0"
								max="59"
								placeholder="Seconds"
								value={customSeconds}
								onChange={(e) => setCustomSeconds(e.target.value)}
							/>
							<button onClick={handleSetTimer} className="button-default ">
								Set Timer
							</button>
						</div>
						<div>
							<div className="timer-controls">
								<span
									onClick={increaseTimer}
									className="button-default button-default-small">
									<Add01Icon size={15} /> +10 sec
								</span>
								<span
									onClick={decreaseTimer}
									className="button-default button-default-small">
									<MinusSignIcon size={15} /> -10 sec
								</span>
							</div>
							{!isTimerRunning ? (
								<span
									onClick={startTimer}
									className="button-default button-action">
									<PlayIcon /> Start Timer
								</span>
							) : (
								<span
									onClick={stopTimer}
									className="button-default button-action">
									<StopIcon /> Stop Timer
								</span>
							)}
						</div>
					</div>
				)}
				{activeView === "timezone" && (
					<TimeZoneConverter isDayTime={isDayTime} />
				)}

				{/* Alert me section */}
				{activeView === "time" && showAlertInput && (
					<div className="alert-section">
						{!isAlertActive ? (
							<div className="alert-input-container" ref={alertMeRef}>
								<span>Alert every</span>
								<input
									type="number"
									min="1"
									placeholder="Minutes"
									value={alertIntervalMinutes}
									onChange={(e) => setAlertIntervalMinutes(e.target.value)}
								/>
								<span>mins</span>
								<button
									onClick={handleSetAlert}
									className="button-default button-default-small button-action">
									<AlarmClockIcon size={15} /> Set Alert
								</button>
							</div>
						) : (
							<div className="alert-message-container">
								<span>
									You will be alerted every {alertIntervalMinutes} minutes.
								</span>
								<span onClick={handleCancelAlert} className="cancel-alert">
									<Cancel01Icon size={15} /> Cancel Alert
								</span>
							</div>
						)}
					</div>
				)}

				{activeView === "time" && !isFullScreen && (
					<div className="info-container">
						<div className="location-display">
							<Location05Icon />
							<span>
								{currentLocation.city}, {currentLocation.state},{" "}
								{currentLocation.country}
							</span>
							<span className="gmt-offset">{gmtOffset}</span>
						</div>

						<div className="sunrise-sunset no-mobile">
							<div className="sunrise" data-tooltip-top="Sunrise">
								<span>
									<SunriseIcon />
								</span>
								<span>{sunriseTime}</span>
							</div>
							<div className="sunset" data-tooltip-top="Sunset">
								<span>
									<SunsetIcon />
								</span>
								<span>{sunsetTime}</span>
							</div>
						</div>

						<div className="unix-time no-mobile">
							<span>Unix Time: {unixTime}</span>
						</div>
					</div>
				)}

				{/* Displaying Time for Different Cities */}
				{!isFullScreen && activeView === "time" && (
					<div className="timezones">
						{cities.map((cityInfo, index) => (
							<div className="timezone-display" key={index}>
								<div>{cityInfo.city}</div>
								<div>
									{new Intl.DateTimeFormat("en-US", {
										hour: "2-digit",
										minute: "2-digit",
										hour12: true,
										timeZone: cityInfo.timeZone,
									}).format(time)}
								</div>
								<small>{cityInfo.timeZone}</small>
							</div>
						))}
					</div>
				)}

				{activeView === "yearlycalendar" && <YearlyCalendar />}

				{activeView === "worldtime" && <WorldTimeMap isDayTime={isDayTime} />}
			</div>

			{/* Footer */}
			<div className={`footer no-mobile ${isDayTime ? "day" : "night"}`}>
				<div className="footer-left">
					<span onClick={() => setActiveView("timezone")}>
						<EarthIcon size={15} /> Timezone converter
					</span>
					<span onClick={() => setActiveView("yearlycalendar")}>
						<Calendar03Icon size={15} /> Calendar
					</span>
					<span onClick={() => setActiveView("worldtime")}>
						<MapsCircle01Icon size={15} /> World time
					</span>
				</div>
				<div className="footer-center"></div>
				<div className="footer-right">
					<a
						href="https://inkedbytes.com/timeontoast"
						target="_blank"
						rel="noreferrer">
						Blog
					</a>
					<a href="https://ranjith.work" target="_blank" rel="noreferrer">
						&copy; ranjith.work
					</a>
				</div>
			</div>

			{/* Sidebar Component */}
			{isSidebarOpen && (
				<>
					<div
						className={`sidebar ${isSidebarOpen ? "open" : ""} ${
							isDayTime ? "day" : "night"
						}`}>
						<div className="sidebar-header">
							<span className="tot-title-sidebar">TIME ON TOAST</span>
							<span
								className="close-menu"
								onClick={() => setIsSidebarOpen(false)}>
								<Cancel01Icon />
							</span>
						</div>
						<div className="sidebar-content">
							<div
								onClick={() => {
									setActiveView("time");
									setIsSidebarOpen(false);
								}}>
								<Time04Icon /> Know the time
							</div>
							<div
								onClick={() => {
									setActiveView("timer");
									setIsSidebarOpen(false);
								}}>
								<Timer02Icon /> Set timer
							</div>
							<div
								onClick={() => {
									setActiveView("stopwatch");
									setIsSidebarOpen(false);
								}}>
								<TimeHalfPassIcon /> Stopwatch
							</div>

							<div
								onClick={() => {
									setActiveView("time");
									scrollToAlertMe();
									setShowAlertInput(!showAlertInput);
									setIsSidebarOpen(false);
								}}>
								<AlarmClockIcon /> Alert me
							</div>
							<div
								onClick={() => {
									handleUseCurrentLocation();
									setIsSidebarOpen(false);
								}}>
								<Location01Icon /> Current location
							</div>
							<div
								onClick={() => {
									setActiveView("timezone");
									setIsSidebarOpen(false);
								}}>
								<EarthIcon /> Timezone converter
							</div>
							<div
								onClick={() => {
									setActiveView("yearlycalendar");
									setIsSidebarOpen(false);
								}}>
								<Calendar03Icon /> Calendar
							</div>
							<div
								onClick={() => {
									setActiveView("worldtime");
									setIsSidebarOpen(false);
								}}>
								<MapsCircle01Icon /> World time
							</div>
						</div>
						<div className="sidebar-footer">
							<a
								href="https://ranjith.work"
								target="_blank"
								rel="noreferrer"
								className="footer-link">
								<span>&copy;</span> <span>ranjith.work</span>
							</a>
							<a
								href="https://inkedbytes.com/timeontoast"
								target="_blank"
								rel="noreferrer"
								className="footer-link">
								blog: inkedbytes.com
							</a>
							<div className="sidebar-logo">
								<img src="logo.png" alt="Time On Toast Logo" />
								<span>Time on Toast</span>
							</div>
						</div>
					</div>
					<div
						className="overlay"
						onClick={() => setIsSidebarOpen(false)}></div>
				</>
			)}

			{/* Mobile Footer Menu */}
			{!isSidebarOpen && (
				<>
					{activeView === "time" && (
						<>
							<div className="mobile-sun-data no-desk">
								<div className="sunrise" data-tooltip-top="Sunrise">
									<span>
										<SunriseIcon />
									</span>
									<span>{sunriseTime}</span>
								</div>
								<div className="sunset" data-tooltip-top="Sunset">
									<span>
										<SunsetIcon />
									</span>
									<span>{sunsetTime}</span>
								</div>
							</div>
							<div className="mobile-date no-desk">{formattedDate}</div>
						</>
					)}
					<div className="mobile-menu">
						<div className="menu-item" onClick={() => setActiveView("time")}>
							<Time04Icon size={20} />
							<span>Know Time</span>
						</div>
						<div
							className="menu-item"
							onClick={() => setActiveView("timezone")}>
							<EarthIcon size={20} />
							<span>Timezone</span>
						</div>
						<div className="menu-item" onClick={() => setActiveView("timer")}>
							<Timer02Icon size={20} />
							<span>Timer</span>
						</div>
						<div
							className="menu-item"
							onClick={() => setActiveView("stopwatch")}>
							<TimeHalfPassIcon size={20} />
							<span>Stopwatch</span>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default App;
