import React, { useState, useEffect } from "react";
import Globe from "react-globe.gl";
import moment from "moment-timezone";
import { SortingAZ02Icon, SortingZA01Icon } from "hugeicons-react";

const WorldTimeMap = ({ isDayTime }) => {
	const [countries, setCountries] = useState([]);
	const [globeWidth, setGlobeWidth] = useState(window.innerWidth / 2);
	const [localTime, setLocalTime] = useState(new Date());
	const [searchTerm, setSearchTerm] = useState("");
	const [isSortedAsc, setIsSortedAsc] = useState(true);

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const response = await fetch("https://restcountries.com/v3.1/all");
				const data = await response.json();
				setCountries(data);
			} catch (error) {
				console.error("Error fetching countries:", error);
			}
		};

		fetchCountries();

		const handleResize = () => {
			setGlobeWidth(window.innerWidth / 2);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setLocalTime(new Date());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const getCurrentTimeForTimezone = (timezones) => {
		if (timezones && timezones.length > 0) {
			const timezone = timezones[0]; // Use the first timezone in the array
			return moment().tz(timezone).format("hh:mm:ss A");
		}
		return "N/A"; // Return N/A if no timezone is found
	};

	const sortCountries = () => {
		const sortedCountries = [...countries].sort((a, b) => {
			const countryA = a.name.common.toLowerCase();
			const countryB = b.name.common.toLowerCase();
			return isSortedAsc
				? countryA > countryB
					? 1
					: -1
				: countryA < countryB
				? 1
				: -1;
		});
		setCountries(sortedCountries);
		setIsSortedAsc(!isSortedAsc);
	};

	return (
		<div className={`container ${isDayTime ? "day-theme" : "night-theme"}`}>
			{/* 3D Globe on the left side */}
			<div className="globe-container">
				<div>
					<Globe
						globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
						backgroundColor="rgba(0,0,0,0)"
						polygonsData={[]}
						polygonCapColor={() => "rgba(200, 0, 0, 0.6)"}
						polygonSideColor={() => "rgba(100, 100, 100, 0.15)"}
						polygonStrokeColor={() => "#111"}
						width={globeWidth}
						height={globeWidth}
					/>
				</div>
			</div>

			{/* List of countries on the right side */}
			<div className="country-list-container">
				<div className="country-list-header">
					<div>
						<div className="country-list-heading">World time</div>
						<div>
							Current time is{" "}
							{localTime.toLocaleTimeString([], {
								hour: "2-digit",
								minute: "2-digit",
								second: "2-digit",
							})}
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<input
							type="text"
							placeholder="Search Country..."
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className="search-input"
						/>
						<span onClick={sortCountries} style={{ cursor: "pointer" }}>
							{isSortedAsc ? <SortingAZ02Icon /> : <SortingZA01Icon />}
						</span>
					</div>
				</div>

				<div className="country-grid">
					{countries
						.filter((country) =>
							country.name.common
								.toLowerCase()
								.includes(searchTerm.toLowerCase())
						)
						.map((country, index) => (
							<div key={index} className="country-card">
								<h3>
									{country.flag} {country.name.common}
								</h3>
								<p>Capital: {country.capital ? country.capital[0] : "N/A"}</p>
								<p>
									Current Time:{" "}
									{country.timezones
										? getCurrentTimeForTimezone(country.timezones)
										: "N/A"}
								</p>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default WorldTimeMap;
