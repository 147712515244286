import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
	Delete02Icon,
	ArrowLeft01Icon,
	ArrowRight01Icon,
	RecordIcon,
} from "hugeicons-react";

const GOOGLE_API_KEY = "AIzaSyB3JJ4EXGGsygVm2F6Dv0_oAd8e3F1sFG4"; // Replace with your Google API Key

const TimeZoneConverter = (props) => {
	const [selectedZones, setSelectedZones] = useState([]);
	const [currentTime, setCurrentTime] = useState(moment());
	const [searchQuery, setSearchQuery] = useState("");
	const [places, setPlaces] = useState([]);

	// Update the current time every minute
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(moment());
		}, 60000); // Update every minute
		return () => clearInterval(interval);
	}, []);

	// Function to search for places using Google Places API
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);

		if (e.target.value.length > 2) {
			// Fetch cities from Google Places API
			fetch(
				`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${e.target.value}&types=(cities)&key=${GOOGLE_API_KEY}`
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.status === "OK") {
						setPlaces(data.predictions);
					}
				})
				.catch((error) => console.error("Error fetching places:", error));
		} else {
			setPlaces([]);
		}
	};

	// Function to get the timezone from latitude and longitude using Google TimeZone API
	const getTimeZone = (placeId) => {
		// Get the place details to retrieve lat and lng
		fetch(
			`https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${GOOGLE_API_KEY}`
		)
			.then((response) => response.json())
			.then((data) => {
				const { lat, lng } = data.result.geometry.location;

				// Now fetch the time zone using lat and lng
				fetch(
					`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
						Date.now() / 1000
					)}&key=${GOOGLE_API_KEY}`
				)
					.then((response) => response.json())
					.then((timeZoneData) => {
						const timeZoneId = timeZoneData.timeZoneId;
						const placeName = data.result.name;

						// Add the place and timezone to the selected list
						setSelectedZones([
							...selectedZones,
							{ name: placeName, timeZoneId },
						]);
					});
			});
	};

	// Handle adding a place from search results
	const handleAddPlace = (placeId) => {
		getTimeZone(placeId);
		setSearchQuery(""); // Clear the search input after selection
		setPlaces([]); // Clear search results after selection
	};

	// Handle removing a time zone from the selected list
	const handleRemoveZone = (zone) => {
		setSelectedZones(selectedZones.filter((z) => z.name !== zone.name));
	};

	// Helper function to determine if it's day or night
	const isDayTime = (zone) => {
		const hour = moment.tz(currentTime, zone.timeZoneId).hour();
		return hour >= 6 && hour < 18; // Consider daytime between 6 AM and 6 PM
	};

	// Function to adjust the current time by a day (previous/next)
	const adjustDay = (days) => {
		setCurrentTime(currentTime.clone().add(days, "days"));
	};

	// Function to reset the current time to now
	const resetToCurrentTime = () => {
		setCurrentTime(moment());
	};

	return (
		<div className="timezone-converter">
			<div className="timezone-header">
				<h1>Time Zone Converter</h1>
			</div>
			<div className="timezone-converter-container">
				{/* Left Side: Search bar and Time Slider */}
				<div className="left-panel">
					{/* Search Input */}
					<div className="search-bar">
						<input
							type="text"
							placeholder="Search for city or place..."
							value={searchQuery}
							onChange={handleSearchChange}
						/>
						<div
							className={`search-results ${props.isDayTime ? "day" : "night"}`}>
							{places.map((place) => (
								<div
									key={place.place_id}
									onClick={() => handleAddPlace(place.place_id)}
									className="search-result-item">
									{place.description}
								</div>
							))}
						</div>
					</div>

					{/* Time Slider */}
					{selectedZones.length > 0 && (
						<div className="time-slider">
							<div className="slider">
								<input
									type="range"
									min={0}
									max={1440}
									value={currentTime.hours() * 60 + currentTime.minutes()}
									onChange={(e) => {
										const newTime = moment()
											.startOf("day")
											.add(e.target.value, "minutes");
										setCurrentTime(newTime);
									}}
								/>
								<div className="slider-labels">
									<span>12:00 AM</span>
									<span>12:00 PM</span>
									<span>11:59 PM</span>
								</div>
							</div>

							{/* Day Navigation Buttons */}
							<div className="slider-buttons">
								<div className="slider-date">
									<span>{currentTime.format("MMM D YYYY")}</span>
								</div>
								<div className="slider-controls">
									<span
										onClick={() => adjustDay(-1)}
										className="tooltip"
										data-tooltip="1 day back">
										<ArrowLeft01Icon size={20} />
									</span>
									<span
										onClick={resetToCurrentTime}
										className="tooltip"
										data-tooltip="Present day">
										<RecordIcon size={20} />
									</span>
									<span
										onClick={() => adjustDay(1)}
										className="tooltip"
										data-tooltip="1 day back">
										<ArrowRight01Icon size={20} />
									</span>
								</div>
							</div>
						</div>
					)}
				</div>

				{/* Right Side: Selected Time Zones */}
				<div className="right-panel">
					<div className="timezone-list">
						{selectedZones.length === 0 && (
							<>Search location to compare timezone.</>
						)}
						{selectedZones.map((zone, index) => (
							<>
								<div
									key={index}
									className={`timezone-item no-mobile ${
										isDayTime(zone) ? "day" : "night"
									}`}>
									<div className="zone-name">{zone.name}</div>

									<div className="zone-time">
										{moment
											.tz(currentTime, zone.timeZoneId)
											.format("ddd, MMM D YYYY")}
									</div>
									<div className="zone-time">
										{moment.tz(currentTime, zone.timeZoneId).format("h:mm A")}
									</div>
									<div className="zone-offset">
										{moment
											.tz(currentTime, zone.timeZoneId)
											.format("z (UTC Z)")}
									</div>
									<div
										onClick={() => handleRemoveZone(zone)}
										className="zone-delete">
										<Delete02Icon size={20} />
									</div>
								</div>

								<div
									key={index}
									className={`timezone-item-m no-desk ${
										isDayTime(zone) ? "day" : "night"
									}`}>
									<div className="timezone-item-first">
										<div className="zone-name">{zone.name}</div>

										<div
											onClick={() => handleRemoveZone(zone)}
											className="zone-delete">
											<Delete02Icon size={20} />
										</div>
									</div>
									<div className="timezone-item-last">
										<div className="zone-time">
											{moment.tz(currentTime, zone.timeZoneId).format("h:mm A")}
										</div>
										<div className="zone-time">
											{moment
												.tz(currentTime, zone.timeZoneId)
												.format("ddd, MMM D YYYY")}
										</div>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimeZoneConverter;
