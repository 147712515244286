import React, { useState, useEffect } from "react";
import moment from "moment";
import Holidays from "date-holidays";
import {
	ArrowLeft01Icon,
	ArrowRight01Icon,
	RecordIcon,
	Calendar03Icon,
} from "hugeicons-react";

const YearlyCalendar = () => {
	const [currentYear, setCurrentYear] = useState(moment().year()); // Manage year state
	const [holidays, setHolidays] = useState({});
	const hd = new Holidays("US"); // Initialize for a specific country. Change as per your preference.

	useEffect(() => {
		// Fetch holidays for the entire year
		const holidayData = hd.getHolidays(currentYear);
		const holidayMap = {};

		holidayData.forEach((holiday) => {
			const date = moment(holiday.date).format("YYYY-MM-DD");
			holidayMap[date] = holiday.name; // Store holiday name by date
		});

		setHolidays(holidayMap);
	}, [currentYear]);

	// Generate an array of months
	const months = Array.from({ length: 12 }, (e, i) =>
		moment().month(i).year(currentYear)
	);

	// Function to generate the days of the month
	const generateMonthDays = (month) => {
		const startOfMonth = month.clone().startOf("month");
		const endOfMonth = month.clone().endOf("month");
		const days = [];

		// Add empty days for the previous month's overflow
		const startDay = startOfMonth.day();
		for (let i = 0; i < startDay; i++) {
			days.push(<div key={`empty-start-${i}`} className="empty-day" />);
		}

		// Add actual days
		for (let day = 1; day <= endOfMonth.date(); day++) {
			const dateStr = month.clone().date(day).format("YYYY-MM-DD");
			const isToday =
				moment().isSame(month.clone().date(day), "day-cal") && "today-calendar";
			const isHoliday = holidays[dateStr] && "holiday";

			days.push(
				<div key={day} className={`day-cal ${isToday} ${isHoliday}`}>
					{day}
					{isHoliday && (
						<span className="tooltip-cal">{holidays[dateStr]}</span>
					)}
				</div>
			);
		}

		return days;
	};

	return (
		<div className="yearly-calendar">
			{/* Year Navigation */}
			<div className="year-navigation">
				<span className="year">
					<Calendar03Icon /> {currentYear}
				</span>
				<div className="year-buttons">
					<ArrowLeft01Icon
						className="arrow-icon"
						onClick={() => setCurrentYear(currentYear - 1)}
					/>
					<RecordIcon
						className="arrow-icon"
						onClick={() => setCurrentYear(moment().year())}
					/>
					<ArrowRight01Icon
						className="arrow-icon"
						onClick={() => setCurrentYear(currentYear + 1)}
					/>
				</div>
			</div>

			{/* Calendar Grid */}
			<div className="calendar-grid">
				{months.map((month, index) => (
					<div key={index} className="month">
						<h3>{month.format("MMMM")}</h3>
						<div className="days-of-week">
							{["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
								<div key={day} className="day-of-week">
									{day}
								</div>
							))}
						</div>
						<div className="days">{generateMonthDays(month)}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default YearlyCalendar;
